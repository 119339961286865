
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import WebMessage from '@/models/WebMessage'
import Expense from '@/models/Expense'
import { PaginationOptionsAll } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FaqModal from '@/components/FaqModal/FaqModal.vue'
import fields from './expenses-home-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    SelectPicker,
    DataTable,
    FaqModal,
  },
})
export default class ExpensesHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public page: number = 1

  public page_size: number = 25

  public records: number = 0

  public loading: boolean = false

  public ready = false

  public sortBy: string = 'created_at'

  public sortDesc: boolean = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {}

  public fields: Array<any> = []

  public overview: any = {
    open_expenses: 0,
    pending_expenses: 0,
    pending_amount: 0,
    due_amount: 0,
  }

  public show_filter_helper: boolean = false

  public query_settings = {
    custom_fields: [
      {
        name: 'status:confirmed',
        value: 'status:confirmed',
      },
      {
        name: 'status:pending',
        value: 'status:pending',
      },
      {
        name: 'status:paid',
        value: 'status:paid',
      },
      {
        name: 'status:error',
        value: 'status:error',
      },
      {
        name: 'commission deductible',
        value: 'is:commission_deductible',
      },
    ],
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  // Default table pagination options
  public get pagination_options() {
    return PaginationOptionsAll
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.fields = fields
    this.loadFilters()
  }

  private isQuickbooksLinked() {
    if (!this.is_quickbooks_connected) {
      WebMessage.error(
        'You need to connect your Quickbookks account before performing this action.',
        [
          {
            text: 'Connect Now!',
            action: (toast: any) => {
              this.$router.push({ name: 'Account' })
              WebMessage.hide(toast.id)
            },
          },
        ],
      )
      return false
    }

    return true
  }

  public rows(ctx) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Expense.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: [...ctx.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.ready = true
      this.getOverview()
      return result.data
    })
  }

  public createExpense() {
    // if (!this.isQuickbooksLinked()) return

    if (this.overview.open_expenses > 0) {
      this.$bvModal.show('create-expence-modal')
      return
    }
    this.createSingleExpense()
  }

  public createSingleExpense() {
    this.$router.push({ name: 'expense' })
  }

  public confirmDelete(expense: Expense) {
    WebMessage.confirm(
      "Are you sure that you want to delete this expense ? You won't be able to restore it!",
      'Delete Expense',
    ).then((value: boolean) => {
      if (value) {
        expense.delete().then(() => {
          this.dataTable.refresh()
        })
      }
    })
  }

  public getOverview() {
    Expense.overview().then(data => {
      this.overview = data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.filter_mode = 'exclusive'

    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'expenses',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('expenses').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'expenses',
      data: null,
    })
  }
}
