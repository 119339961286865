const status: any = {
  confirmed: 'primary',
  paid: 'primary',
  pending: 'warning',
}
export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Date',
    type: 'date',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'client_invoice_number',
    label: 'Invoice #',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'commission_deduct',
    label: 'Commission Deductable',
    type: 'capitalize_replace',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'commission_deduct_mode',
    label: 'Commission Deductable',
    type: 'capitalize_replace',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'deduct_from_commission_at',
    label: 'Deduct At',
    type: 'month',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'badge',
    color: (item: any) => status[item.status],
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
]
